import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "แผนการพัฒนา"
    }}>{`แผนการพัฒนา`}</h1>
    <hr></hr>
    <p>{`แผนการดำเนินการของเรา ซึ่งอาจมีการเปลี่ยนแปลงเล็กน้อยในภายหลัง เนื่องจากที่ว่าเกมของเราเน้นเพื่อการศึกษา ไม่ได้มุ่งเน้นในการลงทุนที่ใช้เงินจำนวนมาก เราจึงไม่ได้จำกัดตัวเองที่จะทำตามแผนเกมการลงทุน แต่จะรับฟังความคิดเห็นต่าง ๆ มาปรับใช้เพื่อพัฒนาเกมที่ดีขึ้นออกมาให้สนุกสนานและได้ความรู้ไปพร้อมกัน`}</p>
    <h3 {...{
      "id": "แผนการระยะแรก--ไตรมาสที่-1-2022-"
    }}>{`แผนการระยะแรก ( ไตรมาสที่ 1, 2022 )`}</h3>
    <p>{`✅ เปิดตลาดซื้อขาย`}</p>
    <p>{`✅ เปิดตัวเกมอย่างเป็นทางการ`}</p>
    <p>{`✅ การจัดการกับตัวละครที่ไม่ใช้`}</p>
    <p>{`✅ เครื่องอำนวยความสะดวกในฟาร์ม`}</p>
    <p>{`✅ จิ้งจอกบุกฟาร์ม`}</p>
    <p>{`✅ การเล่นกับสัตว์`}</p>
    <p>{`✅ สักการะศาลพระภูมิ`}</p>
    <p>{`✅ รองรับภาษาไทย`}</p>
    <h3 {...{
      "id": "แผนการระยะที่สอง--ไตรมาสที่-2-2022-"
    }}>{`แผนการระยะที่สอง ( ไตรมาสที่ 2, 2022 )`}</h3>
    <p>{`✅ ระบบตกปลา`}</p>
    <p>{`⬜️ เครื่องแปรรูป`}</p>
    <p>{`⬜️ พระโคเสี่ยงทาย`}</p>
    <p>{`⬜️ ความสามารถใหม่ของตัวละคร`}</p>
    <p>{`⬜️ ระบบผู้ดูแลฟาร์ม`}</p>
    <h3 {...{
      "id": "แผนการระยะที่สาม--ไตรมาสที่-3-2022-"
    }}>{`แผนการระยะที่สาม ( ไตรมาสที่ 3, 2022 )`}</h3>
    <p>{`⬜️ เมือง Country Life`}</p>
    <p>{`⬜️ แข่งขันวิ่งควาย`}</p>
    <p>{`⬜️ ระบบเพื่อนบ้าน`}</p>
    <h3 {...{
      "id": "แผนการระยะที่สี่--ไตรมาสที่-4-2022-"
    }}>{`แผนการระยะที่สี่ ( ไตรมาสที่ 4, 2022 )`}</h3>
    <p>{`⬜️ เพิ่มเติมระบบ เพื่อพัฒนาเกมให้ดีขึ้น`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      